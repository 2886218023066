<template>
    <div>
        <div class="title-text">
            <span>评论管理</span>
            <el-button class="blue-btn" size="small" @click="openVideoBox">选择视频</el-button>
        </div>
        <div class="video-current" v-if="videoList.length > 0">
            <a :href="videoCurrentItem.video_url" class="video-cover" target="_blank">
                <img class="play-img" :src="require('../../assets/img/image/play_btn.png')" alt="">
                <img :src="videoCurrentItem.cover_url" alt="">
            </a>
            <div class="video-info">
                <div class="text-overflow-2 title">{{videoCurrentItem.desc}}</div>
                <div class="publish-time">{{videoCurrentItem.video_create_time}}</div>
            </div>
        </div>
        <div class="comment-wrapper">
            <div class="module-title">评论列表</div>
            <div class="reply-wrapper">
                <div class="user-avatar">
                    <img :src="userInfo.avatar ? userInfo.avatar : require('../../assets/img/image/user_img.png')" alt="">
                </div>
                <div class="reply-content">
                    <el-input class="reply-box" v-model="replyContent" placeholder="有爱评论，说点儿好听"></el-input>
                    <div class="reply-btn">
                        <el-button class="blue-btn" size="small" @click="sendComment('level1')">发送</el-button>
                    </div>
                </div>
            </div>
            <div class="comment-list" v-if="commentList.length > 0">
                <div class="comment-item" v-for="(commentItem, commentIndex) in commentList" :key="`commentList_${commentIndex}`">
                    <div class="user-avatar">
                        <img :src="require('../../assets/img/image/user_img.png')" alt="">
                    </div>
                    <div class="comment-content">
                        <div class="name">好友</div>
                        <div class="time">{{commentItem.create_time}}</div>
                        <div class="content">{{commentItem.content}}</div>
                        <div class="reply-btn">
                            <div class="btn" @click="commentItem.showLevelOneReply = !commentItem.showLevelOneReply">
                                <i class="el-icon-chat-line-square iconfont"></i>回复
                            </div>
                        </div>
                        <div class="reply-wrapper" style="border-bottom: none" v-show="commentItem.showLevelOneReply">
                            <div class="user-avatar">
                                <img :src="require('../../assets/img/image/user_img.png')" alt="">
                            </div>
                            <div class="reply-content">
                                <el-input class="reply-box" v-model="commentItem.levelOneReplyInput" placeholder="有爱评论，说点儿好听"></el-input>
                                <div class="reply-btn" style="justify-content: flex-end">
                                    <el-button class="blue-btn" size="small" @click="sendComment('level2', commentItem)">发送</el-button>
                                </div>
                            </div>
                        </div>
                        <div class="comment-list" v-show="commentItem.showLevelTwo">
                            <div class="comment-item" v-for="(levelTwoItem, levelTwoIndex) in commentItem.replay.list" :key="`levelTwo_${levelTwoIndex}`">
                                <div class="user-avatar">
                                    <img :src="require('../../assets/img/image/user_img.png')" alt="">
                                </div>
                                <div class="comment-content">
                                    <div class="name">好友</div>
                                    <div class="time">{{levelTwoItem.create_time}}</div>
                                    <div class="content">{{levelTwoItem.content}}</div>
<!--                                    <div class="reply-btn">-->
<!--                                        <div class="btn">-->
<!--                                            <i class="el-icon-chat-line-square iconfont"></i>回复-->
<!--                                        </div>-->
<!--                                    </div>-->
                                </div>
                            </div>
                            <div v-if="commentItem.replay.has_more" @click="getLevelTwoComment(commentItem)"
                                 style="cursor: pointer; color: rgba(28, 31, 35, 0.6); margin: 20px 0 0 52px">查看更多</div>
                        </div>
                        <template v-if="commentItem.reply_comment_total">
                            <div class="open-btn" @click="commentItem.showLevelTwo = !commentItem.showLevelTwo">
                                <span class="line">————</span>
                                <template v-if="commentItem.showLevelTwo">
                                    <span>收起回复</span>
                                    <i class="el-icon-arrow-up iconfont"></i>
                                </template>
                                <template v-else>
                                    <span>展开{{commentItem.reply_comment_total}}条回复</span>
                                    <i class="el-icon-arrow-down iconfont"></i>
                                </template>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="no-data" v-if="hasMore" style="cursor: pointer" @click="getCommentList">查看更多</div>
            </div>
            <div class="no-data" v-else>
                暂无评论
            </div>
        </div>
        <el-drawer title="我是标题" :visible.sync="showVideoBox" :with-header="false" custom-class="video-drawer">
            <div class="video-title">
                <span class="title">作品列表</span>
                <span class="info">共1个视频</span>
            </div>
            <el-scrollbar class="video-content" :native="false">
                <div class="video-item" :class="{current: videoCurrent === videoItem.aweme_id}" @click="toggleVideo(videoItem)"
                     v-for="(videoItem, videoIndex) in videoList" :key="`videoList_${videoIndex}`">
                    <div class="video-cover">
                        <img :src="videoItem.cover_url" alt="">
                    </div>
                    <div class="video-info">
                        <div class="text-overflow-2 title" v-if="videoItem.desc">{{videoItem.desc}}</div>
                        <div class="text-overflow-2 title" v-else>暂无标题</div>
                        <div class="publish-time">{{videoItem.video_create_time}}</div>
                    </div>
                    <div class="video-comment">
                        <i class="el-icon-chat-line-square iconfont"></i>
                        <div>{{videoItem.comment_count}}</div>
                    </div>
                </div>
            </el-scrollbar>
            <el-pagination class="pager-center" style="text-align: center; margin: 30px 0"
                           :hide-on-single-page="true"
                           :current-page="listPages.currentPageNum"
                           :page-size="listPages.eachPageNum"
                           :total="listPages.total"
                           layout="prev, pager, next"
                           @current-change="pagesCurrentChange">
            </el-pagination>
            <div class="load-more" v-if="videoList.length === 0">没有更多视频</div>
        </el-drawer>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                showVideoBox: false,
                videoList: [],
                videoCurrent: null,
                videoCurrentItem: {
                    cover_url: '',
                    desc: '',
                    video_create_time: '',
                    video_url: ''
                },
                replyContent: '',
                userInfo: {},
                commentList: [],
                hasMore: false,
                levelOneCursor: 0,
                listPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
            }
        },
        created() {
            // this.getUserInfo()
            // this.getVideoList()
        },
        methods: {
            // 获取个人信息
            getUserInfo() {
                let params = {
                    platform_type: Number(this.$route.query.platformId) || '',
                    type: 1,
                    id: this.$route.query.id || '',
                }
                this.$httpStudent.axiosGetBy(this.$api.get_account, params, (res) => {
                    if (res.code === 200) {
                        this.userInfo = res.data
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 打开作品列表弹窗
            openVideoBox() {
                this.showVideoBox = true
            },
            // 获取视频列表
            getVideoList() {
                let params = {
                    account_id: this.$route.query.id || '',
                    paging: 1,
                    page: this.listPages.currentPageNum,
                    pageSize: this.listPages.eachPageNum,
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, params, (res) => {
                    if (res.code === 200) {
                        if (res.data.list.length > 0) {
                            this.videoList = res.data.list
                            this.videoCurrent = this.videoList[0].aweme_id
                            this.listPages.total = res.data.total
                            this.getItemVideo()
                        }
                    } else {
                        this.$message.warning(res.message)
                        setTimeout(()=>{
                            window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                        },1000)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换分页
            pagesCurrentChange(val) {
                this.listPages.currentPageNum = val
                this.getVideoList()
            },
            // 获取单个视频, 及评论内容
            getItemVideo() {
                let params = {
                    account_id: this.$route.query.id || '',
                    item_id: this.videoCurrent,
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_video, params, (res) => {
                    // console.log('item', res)
                    if (res.code === 200) {
                        // if (res.data.data.hasOwnProperty('list')) {
                        //     res.data.data.list.forEach(item => {
                        //         this.$set(item, 'showLevelTwo', false)
                        //         this.$set(item, 'showLevelOneReply', false)
                        //         this.$set(item, 'levelOneReplyInput', '')
                        //     })
                        // }
                        this.videoCurrentItem = res.data
                        // this.commentList = res.data.data.list || []
                        // this.hasMore = res.data.data.has_more
                        // this.levelOneCursor = res.data.data.cursor
                        this.getCommentList()
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 切换视频
            toggleVideo(item) {
                this.showVideoBox = false
                this.videoCurrent = item.aweme_id
                this.levelOneCursor = 0
                this.commentList = []
                this.replyContent = ''
                this.getItemVideo()
            },
            // 获取一级评论列表
            getCommentList() {
                let params = {
                    account_id: this.$route.query.id || '',
                    item_id: this.videoCurrent,
                    cursor: this.levelOneCursor,
                    count: 10,
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_comment, params, (res) => {
                    if (res.code === 200) {
                        if (res.data.hasOwnProperty('list')) {
                            res.data.list.forEach(item => {
                                this.$set(item, 'showLevelTwo', false)
                                this.$set(item, 'showLevelOneReply', false)
                                this.$set(item, 'levelOneReplyInput', '')
                            })
                        }
                        let arrTmp = res.data.list || []
                        this.commentList = [...this.commentList, ...arrTmp]
                        this.levelOneCursor = res.data.cursor
                        this.hasMore = res.data.has_more
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 追加二级评论
            getLevelTwoComment(item) {
                let params = {
                    account_id: this.$route.query.id || '',
                    item_id: this.videoCurrent,
                    cursor: item.replay.cursor,
                    count: 10,
                    comment_id: item.comment_id,
                }
                this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_reply, params, (res) => {
                    if (res.code === 200) {
                        item.replay.list = [...item.replay.list, ...res.data.list]
                        item.replay.cursor = res.data.cursor
                        item.replay.has_more = res.data.has_more
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 发送评论，一级评论
            sendComment(level, item) {
                if (level === 'level1' && this.replyContent === '') {
                    return this.$message.warning('请输入评论内容')
                }
                if (level === 'level2' && item.levelOneReplyInput === '') {
                    return this.$message.warning('请输入回复内容')
                }

                let formData = new FormData()
                formData.append('account_id', this.$route.query.id || '')
                formData.append('item_id', this.videoCurrent)
                if (level === 'level1') {
                    formData.append('content', this.replyContent)
                }
                if (level === 'level2') {
                    formData.append('comment_id', item.comment_id)
                    formData.append('content', item.levelOneReplyInput)
                }
                this.$httpStudent.axiosPost(this.$api.tikhoknew_data_reply, formData, (res) => {
                    if (res.code === 200) {
                        this.$message.success(res.message)
                        // this.getItemVideo()
                        if (level === 'level1') {
                            this.levelOneCursor = 0
                            this.commentList = []
                            this.replyContent = ''
                            this.getCommentList()
                        }
                        if (level === 'level2') {
                            item.replay.cursor = 0
                            item.replay.list = []
                            item.levelOneReplyInput = ''
                            this.getCommentList()
                            this.getLevelTwoComment(item)
                        }
                    } else {
                        this.$message.warning(res.message)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped lang="scss">
    .title-text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #16113F;
        font-size: 16px;
    }
    .video-current {
        margin-top: 16px;
        background: #EFEEFF;
        border-radius: 4px;
        display: flex;
        padding: 10px 20px;
        .video-cover {
            width: 89px;
            height: 114px;
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            img {
                max-width: 100%;
                max-height: 100%;
            }
            .play-img {
                position: absolute;
                width: 24px;
                height: 24px;
            }
        }
        .video-info {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 10px 0 10px 16px;
            .title {
                height: 1%;
                flex: 1;
                font-size: 16px;
            }
            .publish-time {
                color: #BCB9CB;
            }
        }
    }
    ::v-deep .el-drawer {
        &.video-drawer {
            outline: none;
            width: 448px!important;
            .el-drawer__body {
                display: flex;
                flex-direction: column;
                height: 100%;
            }
            .video-title {
                padding: 24px 24px 20px;
                display: flex;
                justify-content: space-between;
                .title {
                    font-size: 20px;
                    color: #161823;
                }
                .info {
                    font-size: 14px;
                    line-height: 20px;
                    color: rgba(51,51,51,0.5);
                }
            }
            .video-content {
                margin-left: 24px;
                height: calc(100% - 72px);
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .video-item {
                    display: flex;
                    background: #fff;
                    box-shadow: -1px 0 0 rgba(22,24,35,0.15);
                    border-radius: 0 8px 8px 0;
                    padding: 14px 25px 14px 16px;
                    cursor: pointer;
                    &.current {
                        background: rgba(22,24,35,0.03);
                    }
                    .video-cover {
                        width: 60px;
                        height: 80px;
                        border-radius: 2px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            max-width: 100%;
                            max-height: 100%;
                        }
                    }
                    .video-info {
                        width: 1%;
                        flex: 1;
                        margin-left: 8px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        .title {
                            color: #161823;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 500;
                        }
                        .publish-time {
                            font-style: normal;
                            font-weight: normal;
                            font-size: 12px;
                            line-height: 20px;
                            color: #333;
                            mix-blend-mode: normal;
                            opacity: .4;
                        }
                    }
                    .video-comment {
                        display: flex;
                        width: 58px;
                        margin-right: -25px;
                        align-items: center;
                        flex-direction: column;
                        font-size: 12px;
                        color: rgba(28,31,35,0.6);
                        .iconfont {
                            font-size: 20px;
                            margin: 3px 0;
                        }
                    }
                }
                .load-more {
                    text-align: center;
                    margin: 0 0 82px;
                    padding-top: 34px;
                    font-size: 16px;
                    color: #999;
                }
            }
        }
    }
    .comment-wrapper {
        margin-top: 30px;
        .module-title {
            color: #16113F;
            font-size: 16px;
        }
        .reply-wrapper {
            display: flex;
            margin-top: 16px;
            border-bottom: 1px solid #eee;
            padding-bottom: 16px;
            .user-avatar {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    max-height: 100%;
                    max-width: 100%;
                }
            }
            .reply-content {
                width: 1%;
                flex: 1;
                margin-left: 16px;
                .reply-box {
                    margin-top: 5px;
                    ::v-deep .el-input__inner {
                        background: #F1F0FE;
                        border: none;
                    }
                }
                .reply-btn {
                    text-align: right;
                    margin-top: 10px;
                }
            }
        }
        .comment-list {
            .comment-item {
                padding: 16px 0;
                display: flex;
                border-bottom: 1px solid #eee;
                &:last-child {
                    border-bottom: none;
                }
                .user-avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
                .comment-content {
                    width: 1%;
                    flex: 1;
                    margin-left: 12px;
                    .name {
                        font-size: 16px;
                        margin-top: 5px;
                    }
                    .time {
                        margin-top: 10px;
                        font-size: 12px;
                        color: #999;
                    }
                    .content {
                        font-size: 16px;
                        margin-top: 15px;
                    }
                    .reply-btn {
                        margin-top: 16px;
                        .btn {
                            cursor: pointer;
                            display: inline-flex;
                            align-items: center;
                            font-size: 12px;
                            color: #BCB9CB;
                            .iconfont {
                                margin-right: 4px;
                            }
                        }
                    }
                    .open-btn {
                        font-size: 12px;
                        margin-top: 40px;
                        color: rgba(28,31,35,0.6);
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        .line {
                            margin-right: 4px;
                            color: rgba(22,24,35,0.06);
                        }
                        .iconfont {
                            margin-left: 4px;
                        }
                    }
                }
            }
        }
    }
    .no-data {
        text-align: center;
        margin-top: 30px;
        color: #999;
    }
</style>