<template>
    <div>
        <div class="list-text">关注列表</div>
        <!--min-height: calc(100vh - 251px);-->
        <el-table :data="attentionList" style="width: 100%; margin: 10px 0;" size="medium" class="attention-table"
                  :header-cell-style="{fontWeight: 'normal', height: '46px', color: '#5F5C74'}"
                  :cell-style="{fontSize: '14px',color: '#16113F'}">
            <el-table-column align="left" label="头像/用户名称" :resizable="false" min-width="200">
                <template slot-scope="scope">
                    <div class="person-info">
                        <div class="avatar-box">
                            <img :src="scope.row.avatar ? scope.row.avatar : require('../../assets/img/image/user_img.png')" alt="">
                        </div>
                        <div class="text-overflow name">{{scope.row.nickname}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column align="center" label="性别" :resizable="false" width="120">
                <template slot-scope="scope">
                    <div class="gender" v-if="scope.row.gender === 1">男</div>
                    <div class="gender" v-if="scope.row.gender === 2">女</div>
                </template>
            </el-table-column>
            <el-table-column prop="city" align="center" label="城市" :resizable="false" width="120">
                <template slot-scope="scope">
                    <div class="gender" v-if="scope.row.city">{{scope.row.city}}</div>
                    <div class="gender" v-else >暂无</div>
                </template>
            </el-table-column>
            <el-table-column prop="province" align="center" label="省份" :resizable="false" width="120">
                <template slot-scope="scope">
                    <div class="gender" v-if="scope.row.province">{{scope.row.province}}</div>
                    <div class="gender" v-else >暂无</div>
                </template>
            </el-table-column>
            <el-table-column prop="country" align="center" label="国家" :resizable="false" width="120">
                <template slot-scope="scope">
                    <div class="gender" v-if="scope.row.country">{{scope.row.country}}</div>
                    <div class="gender" v-else >暂无</div>
                </template>
            </el-table-column>
            <!--<el-table-column label="操作" align="center" width="280">-->
                <!--<template slot-scope="scope" :resizable="false">-->
                    <!--<el-link :underline="false" type="danger" class="video-btn" @click="unAttentionBtn(scope.row)" v-show="">取消关注</el-link>-->
                    <!--<el-link :underline="false" type="danger" class="video-btn" @click="attentionBtn(scope.row)" v-show="">关注</el-link>-->
                <!--</template>-->
            <!--</el-table-column>-->
        </el-table>
        <span class="view-more" @click="viewMore" v-if="has_more">查看更多</span>
        <!--<span class="no-more" v-else>暂时没有更多了</span>-->
        <!--<el-pagination-->
                <!--class="pages-center" style="padding-bottom: 10px;"-->
                <!--:current-page="attentionPages.currentPageNum"-->
                <!--:page-size="attentionPages.eachPageNum"-->
                <!--layout="prev, pager, next, jumper"-->
                <!--:total="attentionPages.total"-->
                <!--@current-change="attentionCurrentChange">-->
        <!--</el-pagination>-->
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    export default {
        name: "AttentionManage",
        props: ['accountValue'],
        data() {
            return {
                //关注列表
                attentionList: [],
                //分页
                attentionPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                //下一页游标
                cursor: 0,
                //是否显示'查看更多'
                has_more: false,
                account_id: Number(this.$route.query.id) || ''
            }
        },
        computed: {
            ...mapGetters(['getDouyinId'])
        },
        mounted() {
            setTimeout(() => {
                this.getAttentionList();
            }, 500);
        },
        methods: {
            //获取关注列表
            getAttentionList() {
                if (this.$route.query.id) {
                    let param = {
                        account_id: Number(this.$route.query.id) || '',
                        cursor: this.cursor,
                        count: 10
                    }
                    this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_following, param, (res) => {
                        if (res.code === 200) {
                            if (res.data.list) {
                                this.attentionList = [...this.attentionList, ...res.data.list];
                                this.cursor = res.data.cursor;
                                this.has_more = res.data.has_more;
                            }
                        } else {
                            this.$message.warning(res.message);
                            setTimeout(()=>{
                                window.open(res.data.url, '抖音授权扫码', 'channelmode=0,directories=0,width=1000,height=500,top=200,left=200');
                            },1000)
                        }
                    }, (err) => {
                        console.log(err);
                    })
                } else {
                    let param = {
                        account_id: this.getDouyinId,
                        cursor: this.cursor,
                        count: 10
                    }
                    this.$httpStudent.axiosGetBy(this.$api.tikhoknew_data_following, param, (res) => {
                        if (res.code === 200) {
                            if (res.data.list) {
                                this.attentionList = [...this.attentionList, ...res.data.list];
                                this.cursor = res.data.cursor;
                                this.has_more = res.data.has_more;
                            }
                        } else {
                            this.$message.warning(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })
                }
            },
            //查看更多
            viewMore() {
                this.getAttentionList();
            },
            //关注
            attentionBtn(row) {

            },
            //取消关注
            unAttentionBtn(row) {

            },
            //分页
            attentionCurrentChange(val) {

            }
        }
    }
</script>

<style scoped lang="scss">
    .list-text {
        color: #16113F;
        font-size: 16px;
    }
    .attention-table {
        ::v-deep .el-table {
            ::v-deep .el-table__header-wrapper {
                ::v-deep .el-table__header {
                    tr {
                        th {
                            .cell {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
        ::v-deep .el-table__row {
            td:first-child .cell {
                display: flex;
                justify-content: start;
                align-items: center;
                cursor: pointer;
                .video-cover {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 50px;
                    min-width: 50px;
                    height: 40px;
                    margin: 0 10px 0 0;
                    padding: 0;
                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
    .view-more {
        display: block;
        width: 100%;
        text-align: center;
        cursor: pointer;
        &:hover {
            color: #2338E6;
        }
    }
    .no-more {
        display: block;
        width: 100%;
        text-align: center;
        padding-bottom: 10px;
    }
    .person-info {
        display: flex;
        align-items: center;
        .avatar-box {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            overflow: hidden;
            background: #F1F0FE;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
        .name {
            margin-left: 20px;
            width: 1%;
            flex: 1;
        }
    }
</style>